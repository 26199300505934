.demo-container {
	max-width: 440px;
	margin: 0 auto;
}

.demo-heading {
	text-align: center;
	font-size: 2rem;
	color: var(--smooth-purple);
	margin-bottom: 18px;
}

.demo-input-description {
	color: var(--description);
	font-size: var(--large-text);
	line-height: 32px;
	text-align: center;
}
.demo-button {
	border-radius: var(--bradius);
	cursor: pointer;
	border: none;
	width: 100%;
	padding: 10px 0;
	font-size: 16px;
	color: var(--smooth-purple);
	font-weight: bold;
	background-color: var(--smooth-purple);
	color: var(--white);
	border: var(--border);
	transition: 200ms ease-in-out;
	box-shadow: var(--shadow);
}

.demo-button:hover {
	color: var(--smooth-purple);
	background-color: var(--light-purple);
	border: 2px solid var(--smooth-purple);
	transition: 200ms ease-in-out;
}
.image-display-error {
	color: var(--error);
	margin-top: 10px;
	text-align: center;
}

.demo-displaylogo {
	text-align: center;
	margin-top: 10px;
}

.demo-displaylogo img {
	width: 100px;
	max-width: 100%;
	height: auto;
	border-radius: 5px;
}

.footer {
	width: 100%;
	background-color: #f1f1f1;
	display: flex;
	flex-direction: column;
	padding: 12px 0;
	align-items: center;
}

.footer-top {
	width: 100%;
	display: flex;
	padding: inherit;
}

.footer-left {
	width: 50%;
	padding: 20px 12px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	gap: 24px;
}

.footer-left ul {
	display: flex;
	gap: 24px;
	padding: 10px;
}

.footer-items {
	text-decoration: none;
	font-size: 16px;
	color: var(--smooth-purple);
	font-weight: thin;
	display: flex;
	/* justify-content: space-around; */
	cursor: pointer;
}

.footer-right {
	padding: 12px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	flex-grow: 1;
	max-width: 500px;
	text-align: center;
	margin: 0 auto;
}

.footer-right-heading-container {
	display: flex;
	gap: 16px;
}

.footer-right h4 {
	font-size: var(--large-text);
	color: var(--black);
}

.footer-right img {
	width: 50px;
	height: 50px;
	object-fit: contain;
}

.poweredBy {
	display: flex;
	margin: 10px 0px;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--smooth-purple);
	border-radius: 6px;
	padding: 2px 6px;
	cursor: pointer;
	color: var(--smooth-purple);
	font-weight: 700;
	font-size: var(--large-text);
}

.poweredBy img {
	margin-left: 5px;
	width: auto;
	height: 18px;
}

hr {
	border: none;
	height: 1px;
	background: linear-gradient(
		to right,
		rgba(211, 211, 211, 0),
		lightgray,
		rgba(211, 211, 211, 0)
	);
	width: 100%;
}

@media screen and (max-width: 768px) {
	.footer {
		flex-direction: column;
		align-items: center;
	}

	.footer-top {
		flex-direction: column;
	}

	.footer-left {
		width: 100%;
		border-right: none;
	}

	.footer ul {
		align-items: center;
	}
}

@media screen and (max-width: 480px) {
	.footer-left {
		flex-direction: column;
	}
	.footer-left ul {
		display: flex;
		flex-direction: column;
	}
}

.markdown-body {
	max-width: 1200px;
	border-radius: var(--bradius);
	padding: 64px 32px;
	margin: 0px auto;
}

.markdown-body ul > li {
	list-style-type: disc;
}

.errorMessage {
	width: 100%;
	height: 58vh;
	text-align: center;
	padding: 12rem 0;
	font-size: 2rem;
	font-weight: 700;
}

@media screen and (max-width: 1024px) {
	.markdown-body {
		padding: 32px 16px;
	}
}

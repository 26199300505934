.forgot-password-wrapper {
	margin: 100px 14px;
	display: grid;
	place-items: center;
}

.forgot-password-container {
	padding: 24px;
	max-width: 400px;
	width: 100%;
	border-radius: var(--bradius);
	border: var(--border);
	box-shadow: var(--shadow);
}

.forgot-password-heading {
	color: var(--black);
	font-size: 24px;
	line-height: 32px;
}

.forgot-password-instruction {
	color: var(--description);
	font-size: 14px;
	line-height: 20px;
	margin: 4px 0px 10px;
}

.forgot-password-error {
	color: #f33;
	line-height: 24px;
}

.forgot-password-success {
	color: #34a853;
	line-height: 24px;
}

.forgot-password-container > form {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.forgot-password-container a {
	text-decoration: none;
}

.forgot-password-container > form > button {
	background-color: var(--smooth-purple);
	color: white;
	min-height: 40px;
	width: 100%;
	font-weight: 500;
	padding: 8px 16px;
	border: none;
	border-radius: 6px;
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 16px;
}

.forgot-password-container > form > button:disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.forgot-password-action-text {
	color: var(--black);
	font-size: 14px;
	line-height: 20px;
}

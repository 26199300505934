.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
	padding: 20px;
	border-radius: 5px;
	width: auto;
	height: auto;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-close-button {
	margin-top: 15px;
	border-radius: var(--bradius);
	cursor: pointer;
	border: none;
	padding: 10px 10px;
	font-size: 15px;
	color: var(--smooth-purple);
	font-weight: bold;
	background-color: var(--smooth-purple);
	color: var(--white);
	border: var(--border);
	box-shadow: var(--shadow);
}

.drag-drop-section .drag-area {
	height: 250px;
	padding: 16px;
	border-radius: 5px;
	border: var(--border);
	border-style: dashed;
	background-color: var(--gray-drag-drop-background);
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	align-items: center;
	user-select: none;
	-webkit-user-select: none;
	cursor: pointer;
	text-align: center;
}

.drag-drop-section .drag-area {
	font-size: 18px;
}

.drag-drop-section .select {
	color: var(--smooth-purple);
	cursor: pointer;
	font-weight: bold;
}

.drag-drop-section .file {
	display: none;
}

.image-select-error {
	color: var(--error);
	line-height: 24px;
	margin: 16px auto;
	text-align: center;
}

.modal-bg {
	height: 100%;
	width: 100%;
	background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
	z-index: 1;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	max-width: 500px;
	background-color: white;
	padding: 30px 14px 20px;
	margin: 30px 16px;
	position: relative;
	border-radius: 5px;
	overflow: auto;
	box-shadow: 0 0 5px #444;
}

.modal-close {
	position: absolute;
	right: 10px;
	top: 9px;
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.modal-buttons-container {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	flex-wrap: wrap;
	margin-top: 16px;
}

.modal-button {
	background-color: #d3d3d3;
	border: none;
	border-radius: var(--bradius);
	cursor: pointer;
	padding: 7px 8px;
	font-size: 14px;
	font-weight: 500;
	width: fit-content;
}

.modal-ok-button {
	color: var(--white);
	background-color: rgba(255, 0, 0, 0.7);
}

.modal-ok-button:disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.dashboard-container {
	display: flex;
	max-width: 1440px;
	margin: 0 auto;
}

.dashboard-content-container {
	padding: 64px 32px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 55px;
}

.dashboard-content-container .divider {
	border-bottom: var(--border);
}

.dashboard-content-section {
	display: flex;
	gap: 32px;
}
.custom-input-error {
	color: #ff3333;
	font-size: 16px;
	margin-top: 6px;
}

.dashboard-content-item {
	box-shadow: var(--shadow);
	border: var(--border);
	background-color: var(--white);
	border-radius: var(--bradius);
	padding: 24px 32px;
	text-align: left;
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
}

.dashboard-content-item .current-plan {
	color: rgb(17 24 39);
	font-size: var(--large-text);
	line-height: 28px;
	font-weight: 600;
	margin: 12px 0px;
}

.current-plan-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 12px;
}

.current-plan-status {
	background-color: var(--smooth-gray);
	border-radius: 5px;
	color: #fff;
	padding: 5px 10px;
}
.current-plan-status.active {
	background: #00c9a7;
}

.dashboard-content-item .current-plan-tagline {
	color: var(--description);
	font-size: var(--medium-text);
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 30px;
}

.api-key-warning {
	color: #eed202;
	font-size: var(--medium-text);
	line-height: 20px;
	font-weight: 400;
	margin: 20px 0 20px 0;
}

.api-key-div {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: var(--bradius);
	box-shadow: var(--shadow);
	border: var(--border);
	padding: 12px;
	font-size: 16px;
	font-weight: 500;
}

.api-key-buttons-div {
	display: flex;
	gap: 10px;
}

.api-key-buttons {
	top: 1.5rem;
	right: 1.5rem;
	border: none;
	background: none;
	font-size: larger;
	cursor: pointer;
}

.api-key-header {
	display: flex;
	flex-direction: col-reverse;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 12px;
	position: relative;
}

.close-btn-ak {
	position: absolute;
	top: 0;
	right: 0;
}

.api-key-container-main {
	color: var(--description);
	font-size: var(--medium-text);
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 30px;
	padding: 12px;
	font-size: 16px;
	font-weight: 500;
	width: 50%;
	border-radius: var(--bradius);
	box-shadow: var(--shadow);
	border: var(--border);
	margin: auto;
}

.hide {
	display: none;
}

.dashboard-content-item > button {
	max-width: fit-content;
}

.content-item-heading {
	color: rgb(103, 119, 136);
	font-size: var(--large-text);
	font-weight: 500;
	line-height: 20px;
}

.usage-body-container {
	display: flex;
	gap: 4px;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}

.usage-body-container .data-heading {
	font-size: var(--large-text);
	font-weight: 600;
	line-height: 28px;
	color: rgb(30, 32, 34);
}

.usage-body-container .data {
	font-size: var(--medium-text);
	font-weight: 400;
	line-height: 20px;
	color: var(--description);
	margin-bottom: 12px;
}

.dashboard-reset-date {
	font-size: var(--medium-text);
	font-weight: 400;
	line-height: 20px;
	color: var(--description);
}

.circular-chart {
	width: 150px;
	max-height: 250px;
}

.generate-api {
	box-shadow: var(--shadow);
	border: var(--border);
	background-color: var(--white);
	border-radius: var(--bradius);
	padding: 24px 32px;
	text-align: left;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 0;
}

.api-key-container {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px 32px;
	align-items: baseline;
	justify-content: center;
	flex: 1;
}

.api-key-container > button {
	flex-shrink: 0;
	background-color: #00bd68;
	border: none;
	border-radius: var(--bradius);
	box-shadow: var(--shadow);
	color: var(--white);
	cursor: pointer;
	min-height: 39px;
	padding: 12px;
	font-size: 16px;
	font-weight: 500;
}

.api-key-container > button:disabled,
.api-key-container > button:disabled:hover {
	opacity: 0.5;
	cursor: not-allowed;
}

.api-key-container > button:hover {
	background-image: linear-gradient(-180deg, #00d775, #00bd68);
}

.dashboard-content-item .api-key-input {
	padding: 12px;
	font-size: 16px;
	font-weight: 500;
	width: 70%;
	border-radius: var(--bradius);
	box-shadow: var(--shadow);
	border: var(--border);
}

.api-key-table {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.api-key-table .content-item-heading {
	padding: 20px 24px 16px;
	color: rgba(35, 45, 66, 1);
}

.api-key-table table {
	font-style: normal;
	font-size: var(--medium-text);
	line-height: 175%;
	border-spacing: 0;
	white-space: nowrap;
}

.api-key-table thead tr th {
	background-color: rgb(226, 224, 224);
	padding: 8px;
	font-weight: 500;
	color: var(--smooth-gray);
}

.api-key-table tbody tr td {
	padding: 8px;
	font-weight: 400;
	color: var(--smooth-gray);
	border-top: var(--border);
}

.api-key-column {
	display: flex;
	justify-content: center;
	gap: 12px;
}

.api-key-delete-button {
	margin: 0 auto;
	border: none;
	border-radius: 99px;
	display: flex;
	align-items: center;
	padding: 8px 12px;
	cursor: pointer;
}

.api-key-delete-button > svg {
	height: 20px;
	width: 20px;
	color: rgba(255, 0, 0, 0.7);
}

.api-key-copy {
	border: none;
	border-radius: 99px;
	padding: 8px 12px;
	cursor: pointer;
	display: grid;
	place-items: center;
}

.api-key-copy > svg {
	height: 20px;
	width: 20px;
	color: var(--smooth-gray);
}

.api-key-copied {
	background-color: rgba(221, 249, 228, 1);
	border-radius: 99px;
	padding: 8px 12px;
	display: grid;
	place-items: center;
}

.api-key-copied > svg {
	height: 20px;
	width: 20px;
	color: var(--smooth-gray);
}

.upgrade-button {
	background-color: var(--smooth-purple);
	color: var(--white);
	border: none;
	border-radius: var(--bradius);
	cursor: pointer;
	text-wrap: nowrap;
	padding: 12px;
	font-size: 16px;
	font-weight: 500;
}

.full-width {
	width: 100%;
}

@media screen and (max-width: 768px) {
	.dashboard-container {
		flex-direction: column;
	}

	.dashboard-container > aside {
		width: 100%;
	}

	.dashboard-content-container {
		padding: 32px 16px;
	}

	.dashboard-content-section {
		flex-direction: column;
	}

	.dashboard-content-item {
		padding: 16px;
	}

	.api-key-table {
		overflow: auto;
	}

	.dashboard-content-item .current-plan-tagline {
		margin-bottom: 20px;
	}

	.api-key-container {
		flex-direction: column;
		align-items: flex-start;
	}

	.dashboard-content-item .api-key-input {
		width: calc(100% - 24px);
		max-width: 450px;
	}

	.upgrade-button {
		padding: 8px 14px;
		font-size: 13px;
	}

	.menu-dropdown-button {
		border: 2px solid rgba(33, 50, 91, 0.1);
		border-radius: var(--bradius);
		background: none;
		width: 100%;
		padding: 8px;
		font-size: 14px;
		font-weight: 600;
		color: #1e2022;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.menu-dropdown-icon {
		height: 18px;
		width: 18px;
	}

	.api-key-container-main {
		width: 100%;
	}
}

@media screen and (min-width: 769px) {
	.menu-dropdown-button {
		display: none;
	}
}

.card {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	padding: 20px;
	margin: 20px 0;
	transition: box-shadow 0.3s ease;
	min-height: 100px;
}

.card-header {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 11px;
	color: #666;
}

.card:hover {
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.query-card {
	position: relative;
	padding: 20px;
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 8px;
	background-color: #fff;
}

.operator-container {
	padding: 20px;
}

.tabs {
	display: flex;
	margin-bottom: 20px;
}

.tab {
	padding: 10px 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #f9f9f9;
	cursor: pointer;
	margin-right: 10px;
}

.tab.active {
	background-color: #007bff;
	color: #fff;
	border-color: #007bff;
}

.tab-content {
	position: relative;
}

.query-card {
	position: relative;
	padding: 20px;
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 8px;
	background-color: #fff;
}

.reset-password-wrapper {
	margin: 100px 14px;
	display: grid;
	place-items: center;
}

.reset-password-page {
	max-width: 400px;
	width: 100%;
	border-radius: var(--bradius);
	border: var(--border);
	box-shadow: var(--shadow);
	padding: 24px;
}

.reset-password-title {
	color: var(--black);
	font-size: 24px;
	line-height: 32px;
}

.reset-password-description {
	color: var(--description);
	font-size: 14px;
	line-height: 20px;
	margin: 4px 0px 5px;
}

.reset-password-error {
	color: #f33;
	line-height: 24px;
	margin-bottom: 16px;
}

.reset-password-page > form {
	width: 100%;
}

.reset-password-submit-button {
	background-color: var(--smooth-purple);
	color: white;
	min-height: 40px;
	width: 100%;
	font-weight: 500;
	padding: 8px 16px;
	border: none;
	border-radius: 6px;
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 16px;
}

.reset-password-page > form > button:disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.contact-main-cont {
	padding: 64px 32px;
}

.contact-subcont-first {
	text-align: left;
	color: var(--smooth-purple);
	margin-bottom: 1.6rem;
	font-size: 2rem;
}

.contact-subcont-second {
	display: flex;
	gap: 40px 80px;
}

.contact-subcont-second-first-col {
	display: flex;
	flex-direction: column;
	flex: 4;
}

.contact-subcont-second-sec-col {
	flex: 2;
}

.contact-input-field {
	display: flex;
	column-gap: 20px;
}

.contact-input-field > div {
	width: 100%;
}

.contact-input-field > div > input,
.contact-textarea > textarea {
	padding: 12px;
	border-radius: var(--bradius);
	font-weight: 500;
	font-size: 16px;
	border: var(--border);
	box-shadow: var(--shadow);
}

.contact-textarea {
	position: relative;
	margin: 16px 0;
}

.textarea {
	width: 100%;
	resize: none;
	outline: none;
}

.message-label {
	position: absolute;
	left: 12px;
	color: var(--smooth-gray);
	font-size: 16px;
	font-weight: 500;
	transform: translateY(12px);
	transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
	text-transform: capitalize;
}

.textarea:focus,
.textarea:valid,
.textarea:active {
	outline: none;
	border: solid 2px var(--smooth-purple);
}

.textarea:focus ~ .message-label,
.textarea:valid ~ .message-label,
.textarea:active ~ .message-label {
	color: var(--smooth-purple);
	transform: translateY(-50%) scale(0.8);
	background-color: var(--white);
	padding: 0 0.2em;
}

.contact-button {
	background-color: var(--smooth-purple);
	color: var(--white);
	padding: 12px 20px;
	outline: none;
	border: none;
	border-radius: var(--bradius);
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
	align-self: flex-end;
}

.contact-button:hover {
	background-color: #615ae7;
}

.contact-button:disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.contact-subcont-second {
	display: flex;

	text-align: left;
}

.contact-text > h3 {
	font-size: 2rem;
	color: var(--black);
	font-weight: 800;
	margin-bottom: 12px;
}

.contact-text > p {
	color: rgb(107, 107, 107);
	font-size: var(--large-text);
	line-height: 32px;
}

.contact-get-in-touch > p {
	margin-bottom: 12px;
}

.custom-link,
.custom-link-mail {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.contact-arrow-icon {
	color: #4f46e5;
}

.custom-link > a,
.custom-link-mail > a {
	color: #4f46e5;
	font-weight: 700;
	font-size: var(--large-text);
	text-decoration: none;
	margin-right: 8px;
}

.custom-link-mail {
	margin: 2.5rem 0;
}

.contact-social-icons {
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-top: 20px;
	cursor: pointer;
}

.contact-social-icons > * {
	background-color: #4f46e5;
	color: white;
	padding: 10px;
	border-radius: 50%;
	font-size: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
}

.contact-social-icons svg {
	font-size: 20px;
}

.contact-password-error {
	color: #f33;
	line-height: 24px;
}

.contact-password-success {
	color: #34a853;
	line-height: 24px;
}

@media screen and (max-width: 1024px) {
	.contact-input-field {
		flex-direction: column;
	}
}

@media screen and (max-width: 768px) {
	.contact-main-cont {
		padding: 32px 16px;
		max-width: 600px;
		margin: 0 auto;
	}

	.contact-subcont-first > h1 {
		font-size: 48px;
	}

	.contact-button {
		padding: 8px 14px;
		font-size: 13px;
	}

	.contact-subcont-second {
		flex-direction: column;
	}
}

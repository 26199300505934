.header {
	background: var(--white);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 32px;
	height: 90px;
	transition: background-color 1s ease;
	position: sticky;
	top: 0px;
	z-index: 1;
}

.header.bg {
	background-color: #f1f1f1;
}

.logo {
	color: var(--black);
	cursor: pointer;
	text-decoration: none;
	display: flex;
	align-items: center;
}

.logo > img {
	height: 50px;
	width: 50px;
	margin-right: 10px;
}

.logo > h2 {
	font-size: 32px;
	font-weight: 800;
}

.navbar-container {
	display: flex;
	gap: 50px;
	align-items: center;
	background-color: inherit;
}

.navbar-menu {
	display: grid;
	grid-template-columns: repeat(4, auto);
	gap: 50px;
	background-color: inherit;
}

.nav-links {
	text-decoration: none;
	/* color: var(--smooth-purple); */
	font-size: var(--large-text);
	font-weight: bold;
	color: var(--black);
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.cta-container {
	display: flex;
	align-items: center;
	gap: 28px;
	position: relative;
}

.cta-button {
	border-radius: var(--bradius);
	cursor: pointer;
	border: none;
	padding: 12px 20px;
	font-size: 16px;
	color: var(--smooth-purple);
	font-weight: bold;
	background-color: var(--smooth-purple);
	color: var(--white);
	border: var(--border);
	transition: 200ms ease-in-out;
	box-shadow: var(--shadow);
}

.cta-button:hover {
	color: var(--smooth-purple);
	background-color: var(--light-purple);
	border: 2px solid var(--smooth-purple);
	transition: 200ms ease-in-out;
}

.chevron-down {
	margin-left: 2px;
	transition: 200ms ease-in-out;
}

.rotate-chevron {
	transform: rotate(-90deg);
	transition: transform 150ms ease;
	transition: 200ms ease-in-out;
}

.dropdown {
	position: absolute;
	right: 0;
	top: 60px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	min-width: 160px;
	padding: 8px 0;
	background-color: #fff;
	border-radius: 8px;
}
.menu-items a {
	text-decoration: none;
}
.menu-items div {
	cursor: pointer;
	font-size: inherit;
	color: black;
	text-align: center;
	padding: 11px 16px;
}

.menu-items div:hover {
	background-color: #f2f2f2;
}

@media screen and (min-width: 1001px) {
	.burger-menu {
		display: none;
	}
}

@media screen and (max-width: 1000px) {
	.dropdown {
		right: auto;
	}
	.navbar-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		position: absolute;
		width: 100%;
		top: 90px;
		left: 0;
		padding-bottom: 20px;
		box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
	}

	.burger-menu {
		width: 44px;
		height: 44px;
		cursor: pointer;
	}
}

@media screen and (max-width: 768px) {
	.logo > h2 {
		display: none;
	}

	.navbar-container {
		display: block;
	}
}

@media screen and (max-width: 480px) {
	.header {
		padding: 0px 16px;
	}
}

.image-table-wrapper {
	overflow: auto;
}

.image-table {
	width: 100%;
	text-align: center;
	font-size: var(--medium-text);
	line-height: 175%;
	border-spacing: 0;
	border: var(--border);
	border-radius: var(--bradius);
}

.image-table-heding-row {
	white-space: nowrap;
}

.image-table thead tr th {
	background-color: var(--table-heading-bg);
	padding: 8px;
	font-weight: 500;
	color: var(--smooth-gray);
}

.image-table tbody tr td {
	padding: 8px;
	font-weight: 400;
	color: var(--smooth-gray);
	border-top: var(--border);
}

.image-table .reupload-btn {
	padding: 6px 10px;
	border: none;
	border-radius: 99px;
	cursor: pointer;
}

.image-table .reupload-btn > svg {
	color: var(--smooth-gray);
	height: 20px;
	width: 20px;
}

.image-table tbody tr .error {
	color: var(--error);
}

.profile-cont {
	padding: 64px 32px;
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 32px 100px;
}

.profile-sub-cont {
	padding: 20px;
	background-color: #fff;
	border-radius: var(--bradius);
	border: var(--border);
	box-shadow: var(--shadow);
	text-align: left;
	max-width: 400px;
	width: 100%;
}

.profile-heading {
	font-size: 24px;
	color: var(--black);
}

.profile-border-bottom {
	margin: 20px 0;
	border: var(--border);
}

.profile-label {
	font-weight: bold;
	margin-bottom: 12px;
	display: block;
	color: var(--black);
}

.profile-button {
	background-color: var(--smooth-purple);
	color: var(--white);
	border: none;
	border-radius: var(--bradius);
	cursor: pointer;
	padding: 12px;
	font-size: 16px;
	font-weight: 500;
}

.form-error {
	height: auto;
	max-width: 300px;
	margin-top: 10px;
	margin-bottom: 15px;
	display: flex;
	align-items: flex-end;
	color: #ff3333;
}

.form-error.hidden {
	visibility: hidden;
}

.profile-update-success {
	color: var(--success);
	height: auto;
	max-width: 300px;
	margin-top: 10px;
	margin-bottom: 15px;
	display: flex;
	align-items: flex-end;
}
@media screen and (max-width: 768px) {
	.profile-cont {
		padding: 32px 16px;
		flex-direction: column;
		align-items: center;
	}
}

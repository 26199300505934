.home-container {
	padding: 0px 32px;
	margin-bottom: 80px;
}

@media screen and (max-width: 480px) {
	.home-container {
		padding: 0px 16px;
	}
}

.custom-input-group {
	position: relative;
	margin: 16px 0;
	width: 100%;
}

.custom-input {
	background: none;
	padding: 12px;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
	transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1);
	border-radius: var(--bradius);
	box-shadow: var(--shadow);
	border: var(--border);
}

.custom-input-label {
	position: absolute;
	left: 12px;
	color: var(--smooth-gray);
	font-size: 16px;
	font-weight: 500;
	transform: translateY(12px);
	transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
	text-transform: capitalize;
	cursor: text;
}

.custom-input:focus,
.custom-input:valid {
	outline: none;
	border: solid 2px var(--smooth-purple);
}

.custom-input:focus ~ .custom-input-label,
.custom-input:valid ~ .custom-input-label,
.custom-input:disabled ~ .custom-input-label {
	color: var(--smooth-purple);
	transform: translateY(-50%) scale(0.8);
	background-color: var(--white);
	padding: 0 0.2em;
	font-weight: 600;
	cursor: default;
}

/* Operator.css */

.tabs {
	display: flex;
	width: 100%;
	margin-bottom: 20px;
}

.tab {
	flex: 1;
	/* This makes each tab take up an equal portion of the available width */
	padding: 10px 20px;
	text-align: center;
	/* Center text within the tab */
	cursor: pointer;
	border: 1px solid #ddd;
	background-color: #f8f9fa;
	color: rgb(79, 70, 229) !important;
	/* Force text color to purple */
	transition:
		background-color 0.3s ease,
		color 0.3s ease;
}

.tab.active {
	background-color: rgb(79, 70, 229) !important;
	/* Force the active tab background color to purple */
	color: white !important;
	/* Force the active tab text color to white */
}

.tab:not(.active):hover {
	background-color: #e0e0e0;
	color: rgb(79, 70, 229) !important;
	/* Force text color on hover to purple */
}

.operator-container {
	padding: 20px;
}

.query-card {
	position: relative;
	padding: 20px;
	margin-bottom: 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.query-card > div {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-top: 15px;
}

.view-response-button {
	background-color: #4f46e5;
	padding: 10px 20px;
	color: white;
	border: 2px solid #4f46e5;
	border-radius: 4px;
	cursor: pointer;
}

.view-response-button:hover {
	background-color: #fff !important;
	color: #4f46e5;
	border: 2px solid #4f46e5;
	transition: background-color ease-in-out 300ms;
}

.pagination {
	margin-bottom: 10px;
	text-align: right;
}

.pagination-controls {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}

.pagination-button {
	padding: 8px 16px;
	background-color: rgb(79, 70, 229) !important;
	/* Force button color to purple */
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin: 0 5px;
}

.pagination-button:disabled {
	background-color: #ccc !important;
	cursor: not-allowed;
}

.query-details {
	padding: 10px;
}

.query-details > h2 {
	margin-bottom: 20px;
}

.query-details > p {
	margin-bottom: 10px;
}

.modal {
	width: 50vw;
}

.response-textarea {
	width: 100%;
	height: 150px;
	margin: 0;
	padding: 10px;
	border: 2px double #ccc;
	margin-bottom: 10px;
	resize: none;
}

.send-response-button {
	padding: 10px 30px;
	background-color: rgb(79, 70, 229) !important;
	/* Force button color to purple */
	color: white;
	border: 2px solid #4f46e5;
	border-radius: 4px;
	cursor: pointer;
	margin-left: auto;
	margin-right: auto;
	width: auto;
	display: block;
}

.send-response-button:hover {
	background-color: #fff !important;
	color: #4f46e5;
	border: 2px solid #4f46e5;
	transition: background-color ease-in-out 300ms;
}

.error-message {
	font-size: small;
	color: red;
}

.pagination > a {
	padding: 8px 15px;
	margin: 0 5px;
	border-radius: 5px;
	text-decoration-line: none;
}

.pagination > a:hover {
	background-color: #4f46e5;
	color: #fff;
}

.pagination-active {
	background-color: #4f46e5;
	color: #fff;
}

.card-footer {
	font-size: smaller;
	align-self: baseline;
}

.no-queries {
	height: calc(100vh - 375px);
}

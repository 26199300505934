.container {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(20px);
}

.spinner {
	border: 4px solid var(--smooth-purple);
	border-top: 4px solid lightgray;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	margin: auto; /* to center the spinner */
	height: 50px;
	width: 50px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
